<template>
    <v-card>
        <v-col cols="12" class="px-1">
            <v-row no-gutters>
                <v-col cols="6" v-if="user && false">
                    <user-checkin-card :user="user"></user-checkin-card>
                </v-col>
                <v-col cols="6" v-if="user">
                    <user-weight-card @newTargetWeight="newTargetWeight" :user="user"></user-weight-card>
                </v-col>
            </v-row>
        </v-col>
    </v-card>
</template> 

<script>
import UserWeightCard from '@/views/user/components/UserWeightCard.vue'
import UserCheckinCard from '@/views/user/components/UserCheckinCard.vue'

export default {
    components: {
        UserWeightCard,
        UserCheckinCard,
    },
    props: {
        user: {
            type: Object,
        },
    },
    computed: {},
    methods: {
        newTargetWeight(val) {
            this.user.targetWeight = val
        },
    },
}
</script>
